import styled from "styled-components"
import { FONTS } from "../../styles"

export const Tag = styled.div`
  font-family: ${FONTS.SOHNE_FONT_FAMILY};
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 1.2px;
  padding: 0 9px;
  text-transform: uppercase;
  width: fit-content;
  height: 27px;
`
