import React, { Fragment, FunctionComponent } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import SEO from "../../components/common/seo"
import Style from "./itinerary.style"
import ItineraryCard from "../../components/explore/itineraryCard"
import { COLORS } from "../../styles"
import HashtagInstaFeed from "../../components/instafeed/hashtagInstaFeed"
import ItineraryStop, {
  ItineraryStopProps,
} from "../../components/explore/itineraryStop"
import DetailsHeader from "../../components/detailsHeader"
import moment from "moment"
import { daysToBoolean } from "../../components/explore/explore.utils"
import Carousel from "nuka-carousel"
import { PagingDots } from "../../components/common/dots.style"
import { Media } from "../../Media"

export interface Props {
  data: {
    contentfulExplorePage: {
      slug: string
      publish: boolean
    }
    contentfulItinerary: {
      title: string
      keywords?: string
      creator: string
      image: {
        title: string
        fluid: any
      }
      excerpt: string
      description: {
        description: string
      }
      instaFeedTitle: string
      instaFeedHashtag: string
      openingHours: number
      slug: string
      daysOpen: Array<string>
      itineraryStops: ItineraryStopProps[]
      itineraryPdf: {
        file: {
          url: string
        }
      }
    }

    allContentfulItinerary: {
      edges: {
        node: {
          title: string
          creator: string
          image: {
            title: string
            fluid: any
          }
          excerpt: string
          description: {
            description: string
          }

          slug: string
          daysOpen: Array<string>
          itineraryPdf: {
            file: {
              url: string
            }
          }
        }
      }[]
    }
  }
}

//TODO: create itinerary.service file
function getItineraryHours(openDays: boolean[], startDate: string): string {
  const daysList = openDays.concat(openDays)
  const actualDay = new Date().getDay()
  const nextDaysSlice = daysList.slice(actualDay, actualDay + 7)
  const starti = startDate
  let startHours = 0
  let startMinutes = 0
  const startiClean = starti
    .split(" ")
    .join("")
    .slice(0, starti.indexOf("M") - 2)

  const startAddPM = starti.includes("AM") ? 0 : 12
  if (startiClean.includes(":")) {
    startHours = parseInt(startiClean.split(":")[0]) + startAddPM
    startMinutes = parseInt(startiClean.split(":")[1])
  } else {
    startHours = parseInt(startiClean) + startAddPM
  }

  const itineraryDayOpen = moment()
    .endOf("day")
    .add(nextDaysSlice.indexOf(true) - 1, "days")
    .add(1, "seconds")

  const diffTimeZone = 4
  return itineraryDayOpen
    .add(startHours + diffTimeZone, "hours")
    .add(startMinutes, "minutes")
    .utc()
    .format("YYYYMMDDTHHmmssZ")
}

const Itinerary: FunctionComponent<Props> = ({ data }) => {
  const { slug: explorePageSlug } = data.contentfulExplorePage
  const {
    title,
    keywords,
    creator,
    slug,
    image,
    openingHours,
    description,
    instaFeedTitle,
    instaFeedHashtag,
    itineraryStops,
    itineraryPdf,
    daysOpen,
  } = data.contentfulItinerary

  const itineraries = data.allContentfulItinerary.edges
    .map((edge) => edge.node)
    .filter((itinerary) => itinerary.slug !== slug)

  const daysList: boolean[] = daysToBoolean(daysOpen)
  return (
    <Layout>
      <SEO
        title={title}
        description={description.description}
        keywords={keywords || ""}
      />
      <DetailsHeader
        image={image}
        openingHours={openingHours}
        daysOpened={daysOpen}
        badge={"ITINERARY"}
        title={title}
        subTitle={`BY ${creator}`}
        description={description.description}
        pdf={itineraryPdf}
      />
      <Style.BackButton.Container>
        <Style.BackButton.Button
          $textColor={COLORS.HOLLY_GREEN}
          to={`/${explorePageSlug}`}
          reverse
        >
          Back to Explore
        </Style.BackButton.Button>
      </Style.BackButton.Container>
      <Style.ItineraryStops.Container>
        {itineraryStops &&
          itineraryStops.map(
            (
              {
                title,
                fifthTips,
                endDate,
                startDate,
                stop,
                action,
                link,
                recommendedDuration,
              },
              index
            ) => (
              <Fragment key={index}>
                <ItineraryStop
                  recommendedDuration={recommendedDuration}
                  title={title}
                  fifthTips={fifthTips}
                  startDate={startDate}
                  endDate={endDate}
                  stop={stop}
                  action={action}
                  link={link}
                  endDateDynamic={getItineraryHours(daysList, endDate)}
                  startDateDynamic={getItineraryHours(daysList, startDate)}
                />
                {index !== itineraryStops.length - 1 ? <Style.Line /> : ""}
              </Fragment>
            )
          )}
      </Style.ItineraryStops.Container>
      {instaFeedHashtag && (
        <HashtagInstaFeed
          hashtag={instaFeedHashtag}
          experienceSharingTitle={instaFeedTitle}
          color={COLORS.AQUA_HAZE}
        />
      )}
      <Style.AlsoLike.Container>
        <Style.AlsoLike.Title>YOU MAY ALSO LIKE</Style.AlsoLike.Title>

        <Media greaterThanOrEqual="md">
          <Style.AlsoLike.Itineraries.Container>
            {itineraries
              .slice(0, 3)
              .map(
                ({ title, image, creator, excerpt, daysOpen, slug }, index) => {
                  return (
                    <ItineraryCard
                      key={index}
                      title={title}
                      image={image}
                      creator={creator}
                      excerpt={excerpt}
                      daysOpen={daysOpen}
                      explorePageSlug={explorePageSlug}
                      itinerarySlug={slug}
                    />
                  )
                }
              )}
          </Style.AlsoLike.Itineraries.Container>
        </Media>
        <Media lessThan="md">
          <Carousel
            disableAnimation
            renderCenterRightControls={null}
            renderCenterLeftControls={null}
            renderBottomCenterControls={PagingDots}
            getControlsContainerStyles={(key) => {
              switch (key) {
                case "BottomCenter":
                  return {
                    bottom: "-20px",
                  }
                default:
                  return {}
              }
            }}
          >
            {itineraries
              .slice(0, 3)
              .map(
                ({ title, image, creator, excerpt, daysOpen, slug }, index) => {
                  return (
                    <ItineraryCard
                      key={index}
                      title={title}
                      image={image}
                      creator={creator}
                      excerpt={excerpt}
                      daysOpen={daysOpen}
                      explorePageSlug={explorePageSlug}
                      itinerarySlug={slug}
                    />
                  )
                }
              )}
          </Carousel>
        </Media>
      </Style.AlsoLike.Container>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    contentfulItinerary(slug: { eq: $slug }) {
      title
      keywords
      creator
      image {
        title
        fluid(quality: 90, maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
      excerpt
      description {
        description
      }
      instaFeedTitle
      instaFeedHashtag
      openingHours
      daysOpen
      itineraryStops {
        title
        fifthTips
        endDate
        startDate
        recommendedDuration
        stop {
          __typename
          ... on Node {
            ... on ContentfulRetailer {
              carouselImages {
                id
                title
                fluid(quality: 90, maxWidth: 2000) {
                  ...GatsbyContentfulFluid
                }
              }
              description {
                description
              }
              address
              externalLink
            }
            ... on ContentfulEvents {
              carouselImages {
                id
                title
                fluid(quality: 90, maxWidth: 2000) {
                  ...GatsbyContentfulFluid
                }
              }
              content {
                content
              }

              externalLink
            }
          }
        }

        action
        link
      }
      itineraryPdf {
        file {
          url
        }
      }
    }
    allContentfulItinerary(filter: { title: { ne: "Dummy content" } }) {
      edges {
        node {
          image {
            title
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
          title
          creator
          openingHours
          excerpt
          description {
            description
          }
          slug
          daysOpen
        }
      }
    }
    contentfulExplorePage {
      title
      slug
    }
  }
`

export default Itinerary
