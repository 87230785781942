import React, { FunctionComponent } from "react"
import Style from "./itineraryStop.style"
import Slider from "react-slick"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import AddToCalendarHOC, { SHARE_SITES } from "react-add-to-calendar-hoc"
import moment from "moment"
import CalendarModal from "../../components/common/calendarModal"
import { COLORS } from "../../styles"

export interface ItineraryStopProps {
  title: string
  fifthTips: string[]
  endDate: string
  startDate: string
  stop: any
  action: string | null
  link: string
  endDateDynamic: string
  startDateDynamic: string
  recommendedDuration: string
}

const Button: FunctionComponent<{ onClick: () => void }> = ({
  children,
  onClick,
}) => (
  <Style.Button onClick={onClick}>
    <Style.Plus />
    {children}
  </Style.Button>
)

const ItineraryStop: FunctionComponent<ItineraryStopProps> = ({
  title,
  fifthTips,
  endDate,
  startDate,
  stop,
  action,
  link,
  endDateDynamic,
  startDateDynamic,
  recommendedDuration,
}) => {
  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
  }

  const AddToCalendarModal = AddToCalendarHOC(Button, CalendarModal)

  return (
    <Style.Container>
      <Style.Slider>
        {stop && (
          <Slider {...sliderSettings}>
            {stop.carouselImages &&
              stop.carouselImages.map(image => (
                <Style.Image
                  key={image.id}
                  fluid={image.fluid}
                  alt={image.title}
                />
              ))}
          </Slider>
        )}
      </Style.Slider>
      <Style.TopContainer>
        <Style.Date>
          {startDate} - {endDate}
          <br />
          {recommendedDuration &&
            `Recommended duration: ${recommendedDuration}`}
        </Style.Date>
        <Style.Title>{title}</Style.Title>
        {stop && (
          <Style.Description $textColor={COLORS.CHARCOAL}>
            {stop.description && stop.description.description}
          </Style.Description>
        )}
        <Style.MiddleContainer>
          {fifthTips && (
            <Style.Content>
              <Style.SubTitle>FIFTH TIPS</Style.SubTitle>
              {fifthTips.map((tip, index) => (
                <Style.Information key={index} $textColor={COLORS.CHARCOAL}>
                  {tip}
                </Style.Information>
              ))}
            </Style.Content>
          )}
          {stop && (
            <Style.Content>
              <Style.SubTitle>WHERE</Style.SubTitle>
              <Style.Information>{stop.address}</Style.Information>
              <Style.MoreInfoLink
                $textColor={COLORS.CHARCOAL}
                to={
                  "https://www.google.com/maps/search/?api=1&query=" +
                  encodeURIComponent(stop.address)
                }
              >
                More info
              </Style.MoreInfoLink>
            </Style.Content>
          )}
          {!fifthTips && <Style.Content />}
          <div style={{ width: "100%" }}>
            <AddToCalendarModal
              event={{
                description: stop?.description
                  ? stop.description.description
                  : title,
                duration: moment
                  .duration(
                    moment(endDate?.toString())
                      .utc()
                      .diff(moment(startDate?.toString()).utc())
                  )
                  .asDays()
                  .toString(),
                endDatetime: endDateDynamic,
                location: stop?.address ? stop.address : "fifth avenue",
                startDatetime: startDateDynamic,
                title: title,
              }}
              items={[SHARE_SITES.GOOGLE, SHARE_SITES.OUTLOOK]}
              buttonText="Add To Calendar"
            />
          </div>
          {action && (
            <Style.Link mode="link" to={link}>
              {action.localeCompare("buy tickets", undefined, {
                sensitivity: "accent",
              }) === 0 ? (
                <Style.BuyIcon style={{ verticalAlign: "middle" }} />
              ) : (
                ""
              )}
              {action}
              {action.localeCompare("buy tickets", undefined, {
                sensitivity: "accent",
              }) !== 0 ? (
                <Style.Arrow style={{ verticalAlign: "middle" }} />
              ) : (
                ""
              )}
            </Style.Link>
          )}
        </Style.MiddleContainer>
      </Style.TopContainer>
    </Style.Container>
  )
}

export default ItineraryStop
