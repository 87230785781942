import styled from "styled-components"
import { COLORS, MOBILE_MAX_WIDTH } from "../styles"
import Img from "gatsby-image"
import ClockIcon from "../images/clock.svg"
import CalendarIcon from "../images/calendar.svg"
import { H3, S1, T1, T4 } from "./common/text"
import { Tag } from "./common/tag"
import Button from "./common/button"

const IMAGES_WIDTH = 60

export default {
  Container: styled.div`
    display: flex;
    flex-direction: row;
    color: ${COLORS.WHITE};
    background-color: ${COLORS.HOLLY_GREEN};
    padding-top: 3rem;
    height: 570px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding-top: 1rem;
      flex-direction: column;
      height: unset;
      max-height: 785px;
    }
  `,
  Image: styled(Img)`
    object-fit: content;
    flex-basis: ${IMAGES_WIDTH}%;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-right: 5rem;
      min-height: 300px;
      max-height: 350px !important;
    }
  `,
  DetailsContainer: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 60px 60px 60px;
    flex-basis: 40%;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding: 30px;
      flex-grow: 1;
    }
  `,
  Badge: styled(Tag)`
    background-color: ${COLORS.LIGHT_BLUE_GREY};
    color: ${COLORS.BOTTLE_GREEN};
  `,
  DateTime: styled.div`
    margin-top: 30px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 40px 12px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-top: 25px;
    }
  `,
  Days: styled(T4).attrs({ $textColor: "white" })`
    margin-right: 40px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: column;
      margin-right: 0;
    }
  `,
  ClockIcon: styled(ClockIcon)`
    height: 20px;
    width: 20px;
    margin-right: 10px;
    margin-bottom: -0.35rem;
    fill: ${COLORS.WHITE};
  `,
  CalendarIcon: styled(CalendarIcon)`
    height: 20px;
    width: 20px;
    margin-right: 10px;
    margin-bottom: -0.35rem;
    fill: ${COLORS.WHITE};
  `,
  Title: styled(H3).attrs({ $textColor: "white" })`
    margin-top: 20px;
    margin-bottom: 8px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-top: 15px;
      margin-bottom: 6px;
    }
  `,
  SubTitle: styled(S1).attrs({ $textColor: "white" })`
    margin-bottom: 15px;
  `,
  Description: styled(T1).attrs({ $textColor: "white" })`
    max-width: 90%;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      max-width: unset;
    }
  `,
  //TODO: Clean button component
  DownloadButton: styled(Button)`
    margin-top: 25px;
  `,
}
