import styled from "styled-components"
import Img from "gatsby-image"
import { FONTS, MOBILE_MAX_WIDTH, COLORS } from "../../styles"
import BuyIcon from "../../images/ticket.svg"
import Plus from "../../images/plus.svg"
import Arrow from "../../images/arrow.svg"
import { H5, S1, T1, T4 } from "../common/text"
import { CTA } from "../common/cta"
import Button from "../common/button"

export default {
  Container: styled.div`
    display: flex;
    flex-flow: row;
    overflow-x: hidden;
    justify-content: stretch;
    margin: 20px 0px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      flex-direction: column;
    }
  `,
  Slider: styled.div`
    width: 45%;
    padding-right: 30px;
    .slick-dots {
      position: static;
      margin-left: -13px;
    }
    .slick-dots li {
      margin-right: -13px;
      font-size: 7px;
    }

    .slick-dots li.slick-active button:before {
      color: ${COLORS.PINE_GREEN};
    }
    .slick-dots li button:before {
      color: ${COLORS.BLUE_CHALK_VIOLET};
    }
    .slick-list {
      width: 100%;
    }
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;
      padding-right: 0px;
    }
  `,
  Image: styled(Img)`
    width: 100% !important;
    height: 335px;
    object-fit: contain;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      height: 200px;
    }
  `,
  TopContainer: styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;
    }
  `,
  MiddleContainer: styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 30px 12px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      grid-template-columns: auto;
      position: static;
      bottom: unset;
    }
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Date: styled(S1)`
    margin-bottom: 8px;
  `,
  Title: styled(H5)`
    margin-bottom: 20px;
  `,
  Description: styled(T1)`
    width: 568px;
    height: 80px;
    margin-bottom: 30px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;
      height: unset;
    }
  `,
  SubTitle: styled(S1)`
    margin-bottom: 10px;
  `,
  Information: styled(T1)`
    margin-bottom: 5px;
  `,

  Link: styled(Button)`
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;
    }
  `,
  MoreInfoLink: styled(CTA)``,
  BuyIcon: styled(BuyIcon)`
    margin-right: 7px;
    height: 10px;
    width: 20px;
    margin-bottom: 0.5px;
    &:hover {
      color: inherit;
    }
  `,
  Button: styled(T4)`
    background-color: white;
    font-family: ${FONTS.SOHNE_BUCH};
    height: 50px;
    text-align: center;
    padding: 18px;
    border: solid 0.8px ${COLORS.BOTTLE_GREEN};
    text-decoration: none;
    &:hover {
      background-color: ${COLORS.BOTTLE_GREEN};
      color: ${COLORS.WHITE};
    }
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 100%;
    }
  `,
  Plus: styled(Plus)`
    margin-right: 7px;
    height: 8px;
    color: inherit;
  `,
  Arrow: styled(Arrow)`
    width: 4px;
    height: 8px;
    margin-bottom: 0.5px;
    margin-left: 7px;
    color: inherit;
  `,
}
