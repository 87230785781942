import React from "react"
import Style from "./detailsHeader.style"
import { getReducedDaysOpen } from "./explore/itineraryCard"

interface Props {
  image: { fluid: any; title: string }
  badge: string
  title: string
  subTitle: string
  openingHours: number
  description: string
  daysOpened: string[]
  pdf: any
}

const DetailsHeader = ({
  image,
  title,
  subTitle,
  description,
  badge,
  daysOpened,
  openingHours,
  pdf,
}: Props) => {
  return (
    <Style.Container>
      {image && image.fluid && (
        <Style.Image fluid={image.fluid} alt={image.title} />
      )}
      <Style.DetailsContainer>
        {badge && <Style.Badge>{badge}</Style.Badge>}
        {title && <Style.Title>{title}</Style.Title>}
        {subTitle && <Style.SubTitle>{subTitle}</Style.SubTitle>}
        {description && <Style.Description>{description}</Style.Description>}
        <Style.DateTime>
          <Style.Days>
            <Style.CalendarIcon />
            {getReducedDaysOpen(daysOpened)}
          </Style.Days>
          <Style.Days>
            <Style.ClockIcon />
            {openingHours} Hours
          </Style.Days>
        </Style.DateTime>
        {pdf && (
          <Style.DownloadButton
            mode="link"
            icon="download"
            withBackground
            to={pdf && pdf.file.url}
          >
            DOWNLOAD ITINERARY
          </Style.DownloadButton>
        )}
      </Style.DetailsContainer>
    </Style.Container>
  )
}

export default DetailsHeader
