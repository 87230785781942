import { Link } from "gatsby"
import styled from "styled-components"
import { COLORS, FONTS, MAX_WIDTH_LAYOUT, MOBILE_MAX_WIDTH } from "../../styles"
import LeftArrow from "../../images/cta-arrow-left.svg"
import { CTA } from "../../components/common/cta"
import { S2 } from "../../components/common/text"

export default {
  AlsoLike: {
    Container: styled.div`
      display: flex;
      flex-direction: column;
      padding: 50px 0px;
      overflow-x: hidden;
      .slick-track {
        display: flex;
      }
      .slick-track .slick-slide {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
      }
      .slick-dots {
        position: static;
        margin-left: -13px;
      }
      .slick-dots li {
        margin-right: -13px;
        font-size: 7px;
      }
      .slick-dots li.slick-active button:before {
        color: ${COLORS.PINE_GREEN};
      }
      .slick-dots li button:before {
        color: ${COLORS.BLUE_CHALK_VIOLET};
      }

      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        padding: 30px;
      }
    `,
    Title: styled(S2)`
      text-align: center;
      padding-bottom: 50px;
    `,
    Itineraries: {
      Container: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 12px;
        max-width: fit-content;
        margin: 0 auto;
      `,
    },
  },
  BackButton: {
    Container: styled.div`
      height: 60px;
      background-color: ${COLORS.AQUA_HAZE};
      display: flex;
      align-items: center;
    `,
    Button: styled(CTA)`
      margin-left: 50px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        margin-left: 30px;
      }
    `,
  },
  Line: styled.hr`
    height: 0.75px;
    color: ${COLORS.BOTTLE_GREEN};
  `,
  ItineraryStops: {
    Container: styled.div`
      padding: 30px 50px;
      margin: 0 auto;
      max-width: ${MAX_WIDTH_LAYOUT}px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        padding: 10px 30px 20px 30px;
      }
    `,
  },
}
